import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginComponent } from './login/login.component';
import { AddCommandeComponent } from './pages/commandes/add-commande/add-commande.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommandesComponent } from './pages/commandes/commandes/commandes.component';
import { DetailCommandeComponent } from './pages/commandes/detail-commande/detail-commande.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FactureCommandeComponent } from './pages/commandes/facture-commande/facture-commande.component';
import { StatusCommandesComponent } from './pages/commandes/status-commandes/status-commandes.component';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { DetailVirementsComponent } from './pages/statistics/detail-virements/detail-virements.component';
import { AuthGuard } from './guard/auth.guard';
import { AvatarModule } from 'ngx-avatar';
import { ReturnedCommandesComponent } from './pages/commandes/returned-commandes/returned-commandes.component';
import { VirementsClientComponent } from './pages/statistics/virements-client/virements-client.component';
import { StatisticsCommandesComponent } from './pages/statistics/statistics-commandes/statistics-commandes.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MultipleCommandesComponent } from './pages/commandes/multiple-commandes/multiple-commandes.component';
import { ExchangeCommandeComponent } from './pages/commandes/exchange-commande/exchange-commande.component';
import { DetaisVirementComponent } from './components/shared/dialogs/detais-virement/detais-virement.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddBoxComponent } from './pages/settings/products/windows/add-box/add-box.component';
import { AddPalletComponent } from './pages/settings/pallets/windows/add-pallet/add-pallet.component';
import { AllPalletsComponent } from './pages/settings/pallets/all-pallets/all-pallets.component';
import { AllProductsComponent } from './pages/settings/products/all-products/all-products.component';
import { EditCommandeComponent } from './pages/commandes/edit-commande/edit-commande.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MenuComponent,
    TopComponent,
    FooterComponent,
    LoginComponent,
    AddCommandeComponent,
    CommandesComponent,
    DetailCommandeComponent,
    FactureCommandeComponent,
    StatusCommandesComponent,
    RechercheComponent,
    DetailVirementsComponent,
    ReturnedCommandesComponent,
    VirementsClientComponent,
    StatisticsCommandesComponent,
    MultipleCommandesComponent,
    ExchangeCommandeComponent,
    DetaisVirementComponent,
    BaseLayoutComponent,
    AddBoxComponent,
    AddPalletComponent,
    AllPalletsComponent,
    AllProductsComponent,
    EditCommandeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgReduxModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PerfectScrollbarModule,
    NgbModule,
    QRCodeModule,
    FormsModule,
    AvatarModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AuthGuard,
    DecimalPipe, DatePipe,
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
